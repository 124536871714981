var focusableElements = [
    "a[href]",
    "area[href]",
    "input:not([disabled])",
    "select:not([disabled])",
    "textarea:not([disabled])",
    "button:not([disabled])",
    "iframe",
    "object",
    "embed",
    "[contenteditable]",
    '[tabindex]:not([tabindex^="-"])'
];

var keyList = {
    tab: 9,
    escape: 27
};

var breakpoints = {
    na: 0,
    xs: 320, // Smartphone
    sm: 600, // Tablets
    md: 900, // Tablets Landscape and small desktops
    lg: 1200, // Desktops
    xl: 1800 // Large Desktop
};

cssVars();

//IE Polyfill for closest https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest)
    Element.prototype.closest = function (s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };


// Edge and IE polyfill for smooth scroll 
polyfill();

function elementPosition(el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

document.addEventListener('DOMContentLoaded', function () {

    function runJarallax(x) {
        if (x.matches) {
            jarallax(document.querySelectorAll('.jarallax'), 'destroy');
            jarallax(document.querySelectorAll('.jarallax'), {
                speed: 0.6,
                imgSize: '350%',
                imgPosition: '45% -6%'
            });
        } else {
            jarallax(document.querySelectorAll('.jarallax'), 'destroy');
            jarallax(document.querySelectorAll('.jarallax'), {
                speed: 0.6
            });
        }
    }

    var mobileMatch = window.matchMedia("(max-width: 599px)");
    runJarallax(mobileMatch);
    mobileMatch.addListener(runJarallax);

    // Dialog polyfill
    var dialogElements = document.querySelectorAll('dialog');
    Array.prototype.forEach.call(dialogElements, function (el, i) {
        var currentDialog = dialogElements[i];
        dialogPolyfill.registerDialog(currentDialog);
    });

    shave('.shave-this', 75);
});