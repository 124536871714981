document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('.news-listing-grid')) {
        var msnry = new Masonry('.news-listing-grid', {
            columnWidth: '.news-grid-item',
            itemSelector: '.news-grid-item',
            gutter: 15
        });

        window.addEventListener('resize', function () {
            msnry.layout();
        });
    }
});