(function () {
    document.addEventListener('DOMContentLoaded', () => {
        const key = 'AIzaSyDexVOTEZN24TkEPsVlqwOOnnSvb25gLpU'; // TODO: CHANGE THIS KEY BEFORE GOING LIVE
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap`;
        script.defer = true;

        let map, infowindow, centres = [], filteredCentres = [], markers = [];
        const speciesFilterEl = document.querySelector('#rehomeSpecies');
        const mapContainer = document.querySelector('#rehomingMap');
        const centreDataObjs = document.querySelectorAll('[data-centre-name]');

        const ukCoords = {
            lat: 53.8,
            lng: -4
        };

        const clearMap = () => {
            for (let marker of markers) {
                marker.setMap(null);
            }
            markers = [];
        }

        const createMarker = centre => {
            const marker = new google.maps.Marker({
                position: centre.coords,
                title: centre.name,
                titleDisplay: `<h3 class="marker-title">${centre.name}</h3>`,
                location: `<p class="marker-text">${centre.location}</p>`,
                postcode: `<p class="marker-text">${centre.postcode}</p>`,
                phone: `<a class="marker-link" href="tel:${centre.phone}">${centre.phone}</a>`,
                link: `<a class="marker-link" target="_blank" rel="noopener noreferrer" href="${centre.link}">${centre.link}</a>`,
                species: `<p class="marker-text">Species rehomed: <strong>${centre.species}</strong></p>`,
                animation: google.maps.Animation.DROP,
                map
            });

            marker.addListener("click", () => {
                infowindow.setContent(marker.titleDisplay + marker.location + marker.postcode + marker.phone + marker.link + marker.species);
                infowindow.open(map, marker);
            });

            markers.push(marker);
        }

        window.initMap = function () {
            if (speciesFilterEl !== null && mapContainer !== null) {

                infowindow = new google.maps.InfoWindow();

                map = new google.maps.Map(document.querySelector("#rehomingMap"), {
                    center: ukCoords,
                    zoom: 6,
                });

                if (centreDataObjs.length > 0) {

                    Array.prototype.forEach.call(centreDataObjs, centre => {
                        centres.push(
                            {
                                coords: {
                                    lat: centre.dataset.lat !== undefined ? +centre.dataset.lat : 0,
                                    lng: centre.dataset.lng !== undefined ? +centre.dataset.lng : 0
                                },
                                name: centre.dataset.centreName !== undefined ? centre.dataset.centreName : "",
                                phone: centre.dataset.phone !== undefined ? centre.dataset.phone : "",
                                link: centre.dataset.link !== undefined ? centre.dataset.link : "",
                                location: centre.dataset.location !== undefined ? centre.dataset.location : "",
                                postcode: centre.dataset.postcode !== undefined ? centre.dataset.postcode : "",
                                species: centre.dataset.species !== undefined ? centre.dataset.species : ""
                            }
                        )
                    });
                }

                speciesFilterEl.addEventListener('change', () => {
                    clearMap();

                    const selectedFilter = speciesFilterEl.value.toLowerCase();
                    filteredCentres = centres.filter(x => x.species.toLowerCase().indexOf(selectedFilter) > -1);

                    for (let centre of filteredCentres) {
                        createMarker(centre);
                    }
                });
            }
        };

        if (mapContainer !== null) document.head.appendChild(script);
    });
})();