(function () {
    document.addEventListener('DOMContentLoaded', () => {
        const selectReveals = document.querySelectorAll('.select-reveal');

        Array.prototype.forEach.call(selectReveals, selectReveal => {
            const selectEl = selectReveal.querySelector('select');
            const revealContainer = selectReveal.querySelector('.reveal-container');
            const reveals = revealContainer.querySelectorAll('.reveal');

            selectEl.addEventListener('change', () => {
                const targetId = selectEl.value;
                const targetEl = document.querySelector(targetId);

                Array.prototype.forEach.call(reveals, reveal => {
                    reveal.classList.add('is-d-none');
                });

                targetEl.classList.remove('is-d-none');
            });
        });

    });
})();