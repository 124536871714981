document.addEventListener("DOMContentLoaded", function () {
    const tabContainers = document.querySelectorAll('.point-tabs-holder');
    const pointTabs = document.querySelectorAll('.point-tab');
    const pointInfo = document.querySelectorAll('.point-tabs-info');

    Array.prototype.forEach.call(tabContainers, function (tabContainer, i) {
        const pointTabs = tabContainer.querySelectorAll('.point-tab');
        const pointInfo = tabContainer.querySelectorAll('.point-tabs-info');

        Array.prototype.forEach.call(pointTabs, function (el, y) {
            el.addEventListener('click', function () {
                if (!el.classList.contains('is-active')) {
                    removeActiveState(getActiveElements(tabContainer));
                    addActiveState(el, pointInfo);
                }
            });
        });
    });
}); 

function getActiveElements(tabContainer) {
    const activeElements = tabContainer.querySelectorAll('.is-active');

    return activeElements;
}

function removeActiveState(activeElements) {
    Array.prototype.forEach.call(activeElements, function (el, i) {
        el.classList.remove('is-active');
    });
}

function addActiveState(clickedElement, pointInfo) {
    clickedElement.classList.add('is-active');
    const tabNumber = clickedElement.dataset.tab;

    Array.prototype.forEach.call(pointInfo, function (el, i) {
        if (el.dataset.tab === tabNumber) {
            el.classList.add('is-active');
        }
    });
}