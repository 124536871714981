// Extension of siema for this project

Siema.prototype.updatePagination = function () {
    for (var i = 0; i < this.selector.closest('.siema-carousel').querySelectorAll('.siema-pagination button').length; i++) {
        // if current dot matches currentSlide prop, add a class to it, remove otherwise
        var addOrRemove = this.currentSlide === i ? 'add' : 'remove';
        this.selector.closest('.siema-carousel').querySelectorAll('.siema-pagination button')[i].classList[addOrRemove]('is-active');
    }
};

Siema.prototype.addPagination = function () {
    var _this = this;

    var siemaPagination = document.createElement('div');
    siemaPagination.className = "siema-pagination";

    var _loop = function _loop(i) {
        var btn = document.createElement('button');
        btn.textContent = i + 1;
        btn.addEventListener('click', function () {
            return _this.goTo(i);
        });
        siemaPagination.appendChild(btn);
    };

    for (var i = 0; i < this.innerElements.length; i++) {
        _loop(i);
    }

    this.selector.closest('.siema-carousel').appendChild(siemaPagination);
};

Siema.prototype.destroyPagination = function () {
    var siemaContainer = this.selector.closest('.siema-carousel');
    var paginationToRemove = siemaContainer.querySelectorAll('.siema-pagination')[0];
    siemaContainer.removeChild(paginationToRemove);
};

Siema.prototype.addArrows = function () {
    var _this = this;

    var nextArrowCopy = '<svg class="is-icon is-small has-base-color-dark" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 103.4 185.34" xml:space="preserve"><path d="M51.71,185.34A10.74,10.74,0,0,1,44.11,167l74.36-74.35L44.11,18.32A10.73,10.73,0,0,1,59.29,3.14l81.94,81.94a10.73,10.73,0,0,1,0,15.17L59.29,182.19A10.67,10.67,0,0,1,51.71,185.34Z" transform="translate(-40.97 0)"></path></svg><span class="screen-reader-only"> next slide</span>';
    var prevArrowCopy = '<svg class="is-icon is-small has-base-color-dark" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 103.4 185.34" xml:space="preserve"><path d="M51.71,185.34A10.74,10.74,0,0,1,44.11,167l74.36-74.35L44.11,18.32A10.73,10.73,0,0,1,59.29,3.14l81.94,81.94a10.73,10.73,0,0,1,0,15.17L59.29,182.19A10.67,10.67,0,0,1,51.71,185.34Z" transform="translate(-40.97 0)"></path></svg><span class="screen-reader-only"> previous slide</span>';

    var siemaArrows = document.createElement('div');
    siemaArrows.className = "siema-arrows";

    this.prevArrow = document.createElement('button');
    this.nextArrow = document.createElement('button');
    this.prevArrow.setAttribute('aria-label', 'Previous slide');
    this.nextArrow.setAttribute('aria-label', 'Next slide');
    this.prevArrow.innerHTML = prevArrowCopy;
    this.nextArrow.innerHTML = nextArrowCopy;
    console.log();

    siemaArrows.appendChild(this.prevArrow);
    siemaArrows.appendChild(this.nextArrow);
    this.selector.closest('.siema-carousel').insertBefore(siemaArrows, null);

    this.prevArrow.addEventListener('click', function () {
        return _this.prev();
    });
    this.nextArrow.addEventListener('click', function () {
        return _this.next();
    });
};

Siema.prototype.destroyArrows = function () {
    var siemaContainer = this.selector.closest('.siema-carousel');
    var arrowsToRemove = siemaContainer.querySelectorAll('.siema-arrows')[0];
    if (arrowsToRemove) {
        siemaContainer.removeChild(arrowsToRemove);
    }
};

Siema.prototype.printCurrentSlide = function () {
    var _this = this;


    var siemaSlides = this.selector.querySelectorAll('.slides > div > *');
    for (var i = 0; siemaSlides < siemaSlides.length; i++) {
        siemaSlides[i].tabIndex = 0;
        siemaSlides[i].classList.add('slide');

        siemaSlides[i].addEventListener('focus', function () {
            return _this.goTo(i);
        });

        i === _this.currentSlide ? siemaSlides[i].classList.add('has-active-slide') : siemaSlides[i].classList.remove('has-active-slide');
    };
    //siemaSlides.forEach(function (slide, i) {
        
    //});
};

Siema.prototype.setAutoHeight = function () {

    var that, timeout;

    that = this;

    function autoHeight() {

        var currentItems, min, max, itemHeightList, height, maxHeight, i;

        min = that.currentSlide;
        max = min + that.perPage;
        itemHeightList = [];

        for (i = min; i < max; i++) {
            height = parseInt(that.innerElements[i].scrollHeight, 10);
            itemHeightList.push(height);
        }

        maxHeight = Math.max.apply(null, itemHeightList);

        that.sliderFrame.closest(".slides").style.maxHeight = maxHeight + 'px';
    }

    window.addEventListener('resize', function () {
        that.sliderFrame.closest(".slides").style.maxHeight = '';
        clearTimeout(timeout);
        timeout = setTimeout(autoHeight, 500);
    });
    autoHeight();
};