; (function () {
    var bp = breakpoints.md;
    var mqCarouselMobyleOnly = window.matchMedia('(min-width: ' + bp + 'px)');

    document.addEventListener("DOMContentLoaded", function (event) {
        var mobileCarousels = document.querySelectorAll(".siema-carousel.is-mobile-carousel .slides");

        Array.prototype.forEach.call(mobileCarousels, function (el, i) {
            var carouselMobyleOnly = new Siema({
                selector: mobileCarousels[i],
                duration: 200,
                easing: 'ease-out',
                perPage: 1,
                onInit: function () {
                    this.addPagination();
                    this.updatePagination();
                    this.printCurrentSlide();
                },
                onChange: function () {
                    this.updatePagination();
                    this.printCurrentSlide();
                }
            });

            if (window.innerWidth >= bp) {
                carouselMobyleOnly.destroy(true);
                carouselDestroyExtraMarkup(carouselMobyleOnly);
            }

            carouselMobyleOnlyResetOnBreakPoint(carouselMobyleOnly, mqCarouselMobyleOnly);
        });
    });

    function carouselDestroyExtraMarkup(currentElement) {
        currentElement.destroyPagination();
    };

    function carouselMobyleOnlyResetOnBreakPoint(currentElement, mqCarouselMobyleOnly) {
        if (matchMedia) {
            mqCarouselMobyleOnly.addListener(function () {

                if (mqCarouselMobyleOnly.matches) {
                    currentElement.destroy(true);
                    carouselDestroyExtraMarkup(currentElement);
                } else {
                    currentElement.destroy(true);
                    currentElement.init();
                }
            });
        }
    };
})();