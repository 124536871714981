; (function () {
    var activeDialogCta;
    var activeDialogGroup;

    document.addEventListener("DOMContentLoaded", function (event) {
        var dialogCtas = document.querySelectorAll(".dialog-cta");
        var dialoClosegCtas = document.querySelectorAll(".dialog-close-cta");
        var dialogs = document.querySelectorAll('dialog');
        var dialogNextCtas = document.querySelectorAll('.dialog-next-cta');
        var dialogPrevCtas = document.querySelectorAll('.dialog-prev-cta');

        Array.prototype.forEach.call(dialogCtas, function (el, i) {
            var currentDialogCta = dialogCtas[i];

            currentDialogCta.addEventListener("click", function () {
                openDialog(currentDialogCta);
            });
        });

        Array.prototype.forEach.call(dialoClosegCtas, function (el, i) {
            var currentDialogCloseCta = dialoClosegCtas[i];

            currentDialogCloseCta.addEventListener("click", function () {
                closeDialog(currentDialogCloseCta, activeDialogCta);
            });
        });

        Array.prototype.forEach.call(dialogNextCtas, function (el, i) {
            var currentDialogNextCta = dialogNextCtas[i];

            currentDialogNextCta.addEventListener("click", function () {
                nextDialog(currentDialogNextCta);
            });
        });

        Array.prototype.forEach.call(dialogPrevCtas, function (el, i) {
            var currentDialogPrevCta = dialogPrevCtas[i];

            currentDialogPrevCta.addEventListener("click", function () {
                prevDialog(currentDialogPrevCta);
            });
        });

        Array.prototype.forEach.call(dialogs, function (el, j) {
            var currentDialog = dialogs[j];

            currentDialog.addEventListener("click", function (e) {
                if (e.target.classList.contains('dialog-main')) {
                    closeDialog(currentDialog, activeDialogCta);
                }
            });

            //document.addEventListener("keydown", function (e) {
            //    if (e.keyCode === 27) {
            //        closeDialog(currentDialog, activeDialogCta);
            //    }
            //});
        });
    });

    function openDialog(currentElement) {
        var dialogTarget = currentElement.getAttribute("data-dialog-target");
        var dialog = document.querySelector(dialogTarget);
        var dialogGroup = dialog.getAttribute("dialog-group");
        activeDialogGroup = document.querySelectorAll(`[dialog-group='${dialogGroup}']`);
        activeDialogGroup = Object.keys(activeDialogGroup).map(function (key) { return activeDialogGroup[key] });
        activeDialogGroup[0].getElementsByClassName('dialog-prev-cta')[0].classList.add('not-active');
        activeDialogGroup[activeDialogGroup.length - 1].getElementsByClassName('dialog-next-cta')[0].classList.add('not-active');
        dialog.showModal();
        document.querySelectorAll("body")[0].classList.add("is-no-scroll");
        activeDialogCta = currentElement;
    };

    function closeDialog(currentDialog, currentElement) {
        var dialog = currentDialog.closest("dialog");
        dialog.close();
        document.querySelectorAll("body")[0].classList.remove("is-no-scroll");
        if (currentElement) {
            currentElement.focus();
        }
    };

    function nextDialog(currentDialogNextCta) {
        var dialog = currentDialogNextCta.closest("dialog");
        var dialogPosition = activeDialogGroup.indexOf(dialog);
        dialogPosition++;
        var nextDialog = activeDialogGroup[dialogPosition];

        if (dialogPosition !== activeDialogGroup.length) {
            dialog.close();
            nextDialog.showModal();
        }        
    }

    function prevDialog(currentDialogPrevCta) {
        var dialog = currentDialogPrevCta.closest("dialog");
        var dialogPosition = activeDialogGroup.indexOf(dialog);
        dialogPosition--;
        var prevDialog = activeDialogGroup[dialogPosition];

        if (dialogPosition !== -1) {
            dialog.close();
            prevDialog.showModal();
        }
    }
})();