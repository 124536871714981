; (function () {
    document.addEventListener('DOMContentLoaded', function () {
        if (document.getElementsByClassName('arrow-svg')[0]) {
            var arrow = document.getElementsByClassName('arrow-svg')[0];
            var arrowPosition = elementPosition(arrow);
            document.getElementsByClassName('arrow-svg')[0].addEventListener('click', function () {
                window.scroll({ top: arrowPosition.top - 20, left: 0, behavior: 'smooth' });
            });
        }
    });
}());