; (function () {

    document.addEventListener("DOMContentLoaded", function (event) {
        var standardCarousels = document.querySelectorAll(".siema-carousel.standard-carousel .slides");

        var slidesOnMobile = 1;
        var slidesOnTablet = 2;
        var slidesOnDesktop = 3;

        Array.prototype.forEach.call(standardCarousels, function (el, i) {
            var standardCarousel = standardCarousels[i];
            var arrows;
            var slides = standardCarousel.querySelectorAll('.single-slide');
            var lastSlide = slides[slides.length - 1];

            var aCarousel = new Siema({
                selector: standardCarousels[i],
                duration: 200,
                easing: 'ease-out',
                perPage: {
                    300: slidesOnMobile,
                    600: slidesOnTablet, // 2 items for viewport wider than 800px
                    900: slidesOnDesktop // 3 items for viewport wider than 1240px
                },
                onInit: function () {
                    this.addArrows();
                    this.printCurrentSlide();
                    arrows = standardCarousel.parentElement.querySelectorAll('button');
                    hideShowButtons(this.currentSlide, arrows, slides);
                    var that = this;
                    window.onresize = function (e) { hideShowButtons(that.currentSlide, arrows, slides) };
                },
                onChange: function () {
                    this.printCurrentSlide();
                    hideShowButtons(this.currentSlide, arrows, slides);
                }
            });
        });

        function hideShowButtons(currentSlide, arrows, slides) {
            arrows[0].classList.remove('is-hidden');
            arrows[1].classList.remove('is-hidden');
            if (currentSlide === 0) {
                arrows[0].classList.add('is-hidden');
            } else if (currentSlide !== 0 && arrows[0].classList.contains('is-hidden')) {
                arrows[0].classList.remove('is-hidden');
            }
            if (currentSlide === slides.length - slidesOnDesktop && window.matchMedia("(min-width: 900px)").matches) {
                arrows[1].classList.add('is-hidden');
                return;
            } else if (currentSlide === slides.length - slidesOnTablet && window.matchMedia("(min-width: 600px)").matches) {
                arrows[1].classList.add('is-hidden');
                return;
            } else if (currentSlide === slides.length - slidesOnMobile) {
                arrows[1].classList.add('is-hidden');
                return;
            }
        }
    });
})();