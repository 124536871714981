; (function () {
    var navBp = breakpoints.md;
    var mqNav = window.matchMedia('(min-width: ' + navBp + 'px)');

    document.addEventListener("DOMContentLoaded", function (event) {
        var burgerButton = document.querySelectorAll("button.burger");
        var navLinks = document.querySelectorAll(".navigation .navigation-items > li");
        var secondaryNavLinks = document.querySelectorAll(".sub-navigation-item ul > li");

        Array.prototype.forEach.call(navLinks, function (el, i) {
            var currentNavLink = navLinks[i];

            currentNavLink.addEventListener("click", function () {
                megaNavClickAndTouchHandler(navLinks, currentNavLink);
            });

            currentNavLink.addEventListener("mouseover", function () {
                megaNavMouseOverHandler(navLinks, currentNavLink, navBp);
            });

            currentNavLink.addEventListener("mouseleave", function () {
                megaNavMouseLeaveHandler(navLinks, currentNavLink, navBp);
            });

            megaNavResetOnBreakPoint(navLinks, currentNavLink, mqNav);
        });

        Array.prototype.forEach.call(secondaryNavLinks, function (el, i) {
            var currentNavLink = secondaryNavLinks[i];
            currentNavLink.addEventListener("click", function () {
                megaNavClickAndTouchHandler(navLinks, currentNavLink);
            });

            currentNavLink.addEventListener("mouseover", function () {
                megaNavMouseOverHandler(navLinks, currentNavLink, navBp);
            });

            currentNavLink.addEventListener("mouseleave", function () {
                megaNavMouseLeaveHandler(navLinks, currentNavLink, navBp);
            });

            currentNavLink.onkeydown = function (evt) {
                var evt = evt || window.event;
                megaNavKeyboardControls(evt, navLinks, currentNavLink);
            };

            megaNavResetOnBreakPoint(navLinks, currentNavLink, mqNav);
        });

        Array.prototype.forEach.call(burgerButton, function (el, i) {
            var currentBurgerButton = burgerButton[i];
            var navCointainer = currentBurgerButton.closest('#main-navigation');
            var navHolderCointainer = navCointainer.querySelector('.mobile-nav-holder');

            currentBurgerButton.addEventListener("click", function () {
                megaNavToggleMobile(currentBurgerButton);

                document.onkeydown = function (evt) {
                    var evt = evt || window.event;
                    megaNavMobileKeyboardEsc(currentBurgerButton, evt);
                };
            });

            navHolderCointainer.addEventListener('click', function (evt) {
                closeMegaOnClickOutise(evt, navCointainer, currentBurgerButton)
            })
        });
    });

    function closeMegaOnClickOutise(evt, navCointainer, burger) {
        var elementClickedParent = evt.target.parentNode;

        if (elementClickedParent == navCointainer) {
            megaNavToggleMobile(burger);
        }
    }

    function megaNavResetOnBreakPoint(elements, currentElement, mqNav) {
        if (matchMedia) {
            var navigationBar = currentElement.closest(".navigation-bar");
            var navigationItems = currentElement.closest(".navigation");

            mqNav.addListener(function () {
                if (mqNav.matches) {
                    document.querySelectorAll("body")[0].classList.remove("is-no-scroll");
                    navigationBar.classList.remove("is-active");
                    navigationItems.classList.remove("is-active");
                    navigationBar.querySelectorAll(".burger")[0].classList.remove("is-active");
                    megaNavClosePanels(elements);
                } else {
                    megaNavClosePanels(elements);
                }
            });
        }
    }

    function megaNavClosePanels(elements) {
        document.querySelector('footer').inert = false;
        document.querySelector('main').inert = false;
        for (var j = 0; j < elements.length; j++) {
            if (elements[j].classList.contains("has-sub-navigation")) {
                elements[j].classList.remove("is-active");
            }
        }
    };

    function megaNavClickAndTouchHandler(elements, currentElement) {
        var isSubNavLink = currentElement.classList.contains("has-sub-navigation");
        var isSubNavLinkActive = currentElement.classList.contains("is-active");
        var navBarContainer = currentElement.closest(".navigation-bar");

        if (!isSubNavLink) {
            window.location = currentElement.firstElementChild.getAttribute("href");
        } else if (isSubNavLink && !isSubNavLinkActive) {
            megaNavClosePanels(elements);
            currentElement.classList.add("is-active");
        } else {
            megaNavClosePanels(elements);
        }
    }

    function megaNavMouseOverHandler(elements, currentElement, breakPoint) {
        if (window.innerWidth >= breakPoint) {
            var isSubNavLink = currentElement.classList.contains("has-sub-navigation");

            megaNavClosePanels(elements);
            currentElement.classList.add("is-active");
        }
    }

    function megaNavMouseLeaveHandler(elements, currentElement, breakPoint) {
        if (window.innerWidth >= breakPoint) {
            currentElement.classList.remove("is-active");
        }
    }

    function megaNavKeyboardControls(evt, elements, currentElement) {
        var isSubNavLink = currentElement.classList.contains("has-sub-navigation");
        var isSubNavLinkActive = currentElement.classList.contains("is-active");

        if (evt.keyCode == keyList.escape) {
            megaNavClosePanels(elements);
            elements[i].focus();
        }

        if (evt.keyCode == keyList.tab && isSubNavLink && isSubNavLinkActive) {
            var focusable = currentElement.querySelectorAll(focusableElements.join(", "));
            var firstFocusable = focusable[1];
            var lastFocusable = focusable[focusable.length - 1];
            var currentFocusEl = document.activeElement;

            if (evt.shiftKey && currentFocusEl === firstFocusable) {
                evt.preventDefault();
                megaNavClosePanels(elements);
            }

            else if (!evt.shiftKey && currentFocusEl === lastFocusable) {
                evt.preventDefault();
                megaNavClosePanels(elements);
            }
        }
    };

    function megaNavToggleMobile(currentElement) {
        var isNavOpen = currentElement.classList.contains("is-active");
        var navigationBar = currentElement.closest(".navigation-bar");
        var navigationItems = currentElement.closest(".navigation");

        if (isNavOpen) {
            document.querySelector('footer').inert = false;
            document.querySelector('main').inert = false;
        } else {
            document.querySelector('footer').inert = true;
            document.querySelector('main').inert = true;
        }

        currentElement.classList.toggle("is-active");
        navigationBar.classList.toggle("is-active");
        navigationItems.classList.toggle("is-active");
        document.querySelectorAll("body")[0].classList.toggle("is-no-scroll");
    }

    function megaNavMobileKeyboardEsc(currentElement, evt) {
        var isNavOpen = currentElement.classList.contains("is-active");
        var navigationBar = currentElement.closest(".navigation-bar");
        var navigationItems = currentElement.closest(".navigation");

        if (evt.keyCode == keyList.escape && isNavOpen) {
            document.querySelector('footer').inert = false;
            document.querySelector('main').inert = false;
            currentElement.classList.toggle("is-active");
            navigationBar.classList.toggle("is-active");
            navigationItems.classList.toggle("is-active");
            document.querySelectorAll("body")[0].classList.toggle("is-no-scroll");
            currentElement.focus();
        }
    }
})();