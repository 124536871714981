; (function () {
    document.addEventListener("DOMContentLoaded", function (event) {
        var accordions = document.querySelectorAll(".js-badger-accordion");
        Array.prototype.forEach.call(accordions, function (el, i) {
            var accordion = new BadgerAccordion(accordions[i], {
                openMultiplePanels: false
            });
            Array.prototype.forEach.call(accordion.toggleEl, function (el, i) {
                el.addEventListener('click', function () {
                    accordion._setPanelHeight();
                });

            });
        });

        var badgerPanels = document.querySelectorAll(".badger-accordion__panel");

        Array.prototype.forEach.call(badgerPanels, function (el, i) {
            if (window.matchMedia("(min-width: 600px)").matches & !el.classList.contains('always-acc')) {
                el.classList.remove('-ba-is-hidden');
            }
            window.addEventListener('resize', function () {
                if (window.matchMedia("(min-width: 600px)").matches & !el.classList.contains('always-acc')) {
                    el.classList.remove('-ba-is-hidden');
                } else if (!el.classList.contains('always-acc')) {
                    el.classList.add('-ba-is-hidden');
                }
            });
        });

    });
})();